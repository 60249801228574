import { getOptionValue } from './get-option-value';

export const getColorCode = getOptionValue(x => x.type === 'VisualSwatches');

export const getFirstSelectedBundleColorCode = (selections, configurableProduct) => {
    const selectedItemColorCode: string[] = [];
    configurableProduct.bundledProducts.some((bundleOption: {
        id: string;
        selections: [{'selection_id': string; color: string}];
    }) => {
        const selectionId = selections[bundleOption.id];
        const selectionItem = bundleOption.selections.find(
            selection => selection.selection_id === selectionId,
        );
        if (selectionItem?.color) {
            selectedItemColorCode.push(selectionItem.color);
            return true;
        }
        return false;
    });
    return selectedItemColorCode.length ? selectedItemColorCode[0] : undefined;
};
