import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { WishlistButton } from 'ui/component/embroidery-configurator/components/add-to-cart-summary/wishlist-button';
import { useConfirm } from 'ui/component/embroidery-configurator/hooks/use-confirm';
import { ItemList } from '../../item-list';
import styles from './style.css';

interface ISubmitButtonProps {
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: add embroideryPage type
}

export const SubmitButton: React.FC<ISubmitButtonProps> = ({ embroiderer, embroideryPage }) => {
    const t = usePhraseTranslater();
    // CWM2-7917: Issue is logo/flag option has "Next" button and "Add To Cart" button
    // we do not want user to accidentally click on Add To Cart without confirming logo/flag
    // Add To Cart button will be enabled, Once all logo/flag options are selected and customer click "Next"
    const isDisabled = embroideryPage.type === 'logos' || embroideryPage.type === 'flags' || embroiderer.cannotSave;
    const shouldBeLocked = isDisabled && (embroideryPage.type === 'logos' || embroideryPage.type === 'flags');
    const { ConfirmationDialog, confirm } = useConfirm();

    const handleSubmit = async () => {
        if (embroiderer.isOrderMultiple) {
            const isConfirmed = await confirm();
            if (isConfirmed) {
                await embroiderer.save();
            }
        } else {
            await embroiderer.save();
        }
    };

    return (
        <>
            <div className={styles.submitButtonWrap}>
                <AccessibleButton
                    tag="div"
                    data-test="submit-embroidery"
                    action={handleSubmit}
                    className={classes(styles.saveButton, {
                        [styles.disabled]: isDisabled,
                    })}
                    isLocked={shouldBeLocked}
                >
                    {embroiderer.isQuickView ? t('update item') : t('add to cart')}
                </AccessibleButton>
                <WishlistButton productId={embroiderer.product.id} />
            </div>
            <ConfirmationDialog
                title={t('Embroidery')}
                notice={t('I agree, Embroidery options will be applied to all items.')}
                confirmActionTitle={t('Add To Cart')}
            >
                <ItemList embroiderer={embroiderer} />
            </ConfirmationDialog>
        </>
    );
};
