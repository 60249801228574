import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import {
    IMultiSelection,
} from '@silkpwa/module/react-component/product-config/configurable-product/configurable-product';
import { EmbroidererProps } from '../../add-embroidery-state';
import { Item } from './item';
import styles from './style.css';

interface IItemListProps {
    className?: string;
    embroiderer: EmbroidererProps;
}

export const ItemList: React.FC<IItemListProps> = ({ className = '', embroiderer }) => {
    if (!embroiderer.isOrderMultiple || !embroiderer.multiSelections?.length) return null;
    return (
        <div className={classes(styles.itemList, className)}>
            {
                embroiderer.multiSelections.map((multipleSelection: IMultiSelection) => (
                    <Item item={multipleSelection} quantity={embroiderer.quantityState} />
                ))
            }
        </div>
    );
};
