import React, { useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { Overlay } from 'ui/component/overlay';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface IConfirmationDialogProps {
    title: string;
    notice: string;
    confirmActionTitle?: string;
    cancelActionTitle?: string;
}

interface IPromise {
    resolve: (value: boolean) => void;
    reject: (reason?: any) => void;
}

export const useConfirm = () => {
    const [promise, setPromise] = useState<IPromise | null>(null);

    const confirm = () => new Promise((resolve, reject) => {
        setPromise({ resolve, reject });
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        if (promise) {
            promise.resolve(true);
            handleClose();
        }
    };

    const handleCancel = () => {
        if (promise) {
            promise.resolve(false);
            handleClose();
        }
    };

    const handleDoNotAgree = () => {
        // empty body
    };

    const ConfirmationDialog: React.FC<React.PropsWithChildren<IConfirmationDialogProps>> = ({
        title,
        notice,
        confirmActionTitle,
        cancelActionTitle,
        children,
    }) => {
        const t = usePhraseTranslater();
        return (
            <>
                <Overlay
                    active={promise !== null}
                    close={handleClose}
                    className={styles.overlayWrapper}
                >
                    <div className={classes(styles.overlayContent, styles.twoColumn)}>
                        <div className={classes(styles.column, styles.columnList)}>
                            {children}
                        </div>
                        <div className={classes(styles.column, styles.columnDetail)}>
                            <div className={styles.detail}>
                                <div className={styles.title}>
                                    {title}
                                </div>
                                <div className={styles.notice}>
                                    {notice && (
                                        <>
                                            <p>
                                                <sup>*</sup>
                                                {notice}
                                            </p>
                                            <AccessibleButton
                                                tag="button"
                                                action={handleDoNotAgree}
                                                className={styles.noticeCheckbox}
                                                isLocked
                                            >
                                                <i className="fa-solid fa-xmark" />
                                            </AccessibleButton>
                                        </>
                                    )}
                                </div>
                                <AccessibleButton
                                    tag="div"
                                    action={handleConfirm}
                                    className={styles.confirmButton}
                                >
                                    {confirmActionTitle ?? t('confirm')}
                                </AccessibleButton>
                            </div>
                            <div className={styles.secondaryAction}>
                                <AccessibleButton
                                    tag="a"
                                    action={handleCancel}
                                >
                                    {cancelActionTitle ?? t('close')}
                                </AccessibleButton>
                            </div>
                        </div>
                    </div>
                </Overlay>
            </>
        );
    };
    return { ConfirmationDialog, confirm };
};
