import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { getColorCode, getFirstSelectedBundleColorCode } from 'ui/util/get-color-code';
import { getLiquidPixelSku } from 'ui/util/get-liquid-pixel-sku';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { getProductTypeBundle, getProductTypeConfigurable } from 'ui/util/get-product-type';
import {
    IMultiSelection,
} from '@silkpwa/module/react-component/product-config/configurable-product/configurable-product';
import { downloadBase64Pdf, urlToBase64 } from '../util';
import { connectProductEntity } from './connect-product';

interface ConnectComponentProps {
    simpleProduct: any;
    selections: any;
    configurableProduct: any;
    quantity: number;
    [key: string]: any;
}

interface ConnectComponentState {
    options: [];
    embroideryState: {logoSet: {}};
    faces: [];
    [key: string]: any;
}

export interface IProductInfo {
    id: number;
    colorSku: string;
    productColor: string;
    liquidPixelSku?: string;
    configurableSku?: string;
    quantity: number;
    selections: IProductConfigData['selections'];
}

export interface IOption {
    id: number;
    label: string;
    type: string;
    value: string;
}

export interface IOptions {
    [key: string]: IOption;
}

export interface ISimpleOption {
    id: number;
    label: string;
    options: IOption[];
    type: string;
}

export interface ISimpleOptions {
    [key: string]: ISimpleOption;
}

interface IProductInfoPayload {
    quantity: number;
    selections: IProductConfigData['selections'];
    lpImages: any; // TODO typing
    selectionsQty: number;
    productType: string;
    cartId?: string;
}

function Connect(Component: React.ComponentType<any>) {
    return class ConnectAPI extends React.Component<ConnectComponentProps, ConnectComponentState> {
        private _lastProduct;

        private _lastSelections;

        private _lastConfigProduct;

        private _restoredItem;

        private _isLoggedIn: boolean;

        constructor(props: ConnectComponentProps) {
            super(props);
            this.state = {
                options: [],
                embroideryState: {
                    logoSet: {},
                },
                loadState: 'loading',
                faces: [],
            };

            this.componentDidMount = this.performUpdates.bind(this);
            this.componentDidUpdate = this.performUpdates.bind(this);
        }

        setSelectedItem(items) {
            const { configItemId } = this.props;

            [this._restoredItem] = items
                .filter(x => (
                    x.item_id === configItemId
                ));
        }

        getColorSku() {
            const {
                simpleProduct,
                selections,
                configurableProduct,
            } = this.props;
            const simpleOptions: ISimpleOption[] = simpleProduct.options;
            let colorSku: string = simpleProduct.sku ?? '';

            if (!simpleOptions || !simpleOptions.length) {
                return colorSku;
            }

            const sizeOptions: ISimpleOption[] = simpleOptions.filter((option: ISimpleOption) => (
                option.label.toLowerCase() === 'size'
            ));

            const colorOptions: ISimpleOption[] = simpleOptions.filter((option: ISimpleOption) => (
                option.label.toLowerCase() === 'color'
            ));

            /**
             * We have to be sure there are two options `size` and `color`.
             * Otherwise, return simple sku.
             */
            if (!sizeOptions || !sizeOptions.length || !colorOptions || !colorOptions.length) {
                return colorSku;
            }

            const foundColor: ISimpleOption = colorOptions[0];
            const { id } = foundColor;
            const chosenColorId: number = selections[id];
            const foundColorOptions: IOption[] = foundColor.options;

            const options: IOption[] = foundColorOptions.filter((option: IOption) => (
                option.id === chosenColorId
            ));

            if (options && options.length) {
                const option: IOption = options[0];
                const result = option.label.match(/^.*[(](.*)[)]$/);
                colorSku = result && result[1] ? `${configurableProduct.sku}${result[1]}` : '';
            }

            return colorSku;
        }

        getProductColor() {
            const { selections, configurableProduct } = this.props;
            if (configurableProduct.type === getProductTypeBundle()) {
                return getFirstSelectedBundleColorCode(selections, configurableProduct);
            }
            return getColorCode(selections, configurableProduct);
        }

        private getSelectionsAndQuantity(): {selections: IProductInfo['selections']; quantity: number} {
            const {
                quantity,
                selections,
                isOrderMultiple,
                multiSelections,
                configurableProduct,
            } = this.props;

            let selectionsOrFirstFromMultiOrder = selections;
            let quantityOrFirstFromMultiOrder = quantity;
            // updates selections and qty from first selected multi order options
            if (
                isOrderMultiple &&
                multiSelections.length &&
                configurableProduct.type === getProductTypeConfigurable()
            ) {
                const multiSelection: IMultiSelection = multiSelections.shift();
                selectionsOrFirstFromMultiOrder = multiSelection.selections;
                quantityOrFirstFromMultiOrder = multiSelection.qty;
            }
            return {
                selections: selectionsOrFirstFromMultiOrder,
                quantity: quantityOrFirstFromMultiOrder,
            };
        }

        performUpdates() {
            const {
                simpleProduct,
                selections,
                configurableProduct,
                quantity,
                account,
                loadProductData,
            } = this.props;

            if (simpleProduct?.id !== this._lastProduct ||
                selections !== this._lastSelections ||
                configurableProduct?.id !== this._lastConfigProduct ||
                account.isLoggedIn !== this._isLoggedIn
            ) {
                const isPriceReload = (
                    this._isLoggedIn !== undefined &&
                    account.isLoggedIn !== this._isLoggedIn
                );

                if (isPriceReload) {
                    loadProductData(simpleProduct.id);
                }

                this._lastProduct = simpleProduct.id;
                this._lastConfigProduct = configurableProduct.id;
                this._lastSelections = selections;
                this._isLoggedIn = account.isLoggedIn;

                const colorSku = this.getColorSku();
                const productColor = getColorCode(selections, configurableProduct);

                this.load({
                    id: configurableProduct.id,
                    colorSku,
                    productColor,
                    selections,
                    quantity,
                    liquidPixelSku: getLiquidPixelSku(simpleProduct),
                }, isPriceReload);

                this.publishOpened();
            }
        }

        publishOpened() {
            const {
                appEventBus,
                configurableProduct,
            } = this.props;

            appEventBus.publish(
                'chefworks.embroidery.opened',
                configurableProduct,
            );
        }

        publishSaved() {
            const {
                appEventBus,
                configurableProduct,
                quantity,
            } = this.props;

            // generic saved event
            appEventBus.publish(
                'chefworks.embroidery.saved',
                configurableProduct,
            );

            // event specific to whether this was adding or updating
            // the item
            const event = (() => {
                if (quantity === 0) return 'removed';
                if (this._restoredItem) return 'updated';
                return 'added';
            })();
            appEventBus.publish(
                `chefworks.embroidery.${event}`,
                configurableProduct,
            );
        }

        async load(productInfo: IProductInfo, isPriceReload: boolean) {
            const { embroideryRepository, cartItems, ecommerceConfig } = this.props;

            this.setState({ loadState: 'loading' });

            const { quantity } = productInfo;

            this.setSelectedItem(cartItems);

            const config = await embroideryRepository.loadEmbroideryConfig(
                productInfo,
                cartItems,
                this._restoredItem,
                ecommerceConfig.selectors.getCurrentConfig(),
            );

            this.setState({
                ...config,
                quantity,
                loadState: 'loaded',
                isPriceReload,
            });
        }

        async save(options, lpImages) {
            const { getCartList } = this.props;
            this.setState({ loadState: 'saving' });
            try {
                const productInfoPayload = this.prepareProductPayload(lpImages);
                await this.saveAllEmbroidery(productInfoPayload, options);
                await getCartList();
                this.publishSaved();
            } catch (e) {
                this.setState({ loadState: 'loaded' });
                throw e;
            }
        }

        /**
         * return prepared product payload array for add to cart
         * @param lpImages
         * @private
         */
        private prepareProductPayload(lpImages): IProductInfoPayload[] {
            const {
                quantity,
                selections,
                selectionsQty,
                isOrderMultiple,
                multiSelections,
                configurableProduct,
            } = this.props;
            let productInfoPayload: IProductInfoPayload[] = [];
            // prepare payload when multiple size option is selected
            if (
                isOrderMultiple &&
                multiSelections.length &&
                configurableProduct.type === getProductTypeConfigurable()
            ) {
                productInfoPayload = multiSelections.map((
                    multiSelection: IMultiSelection,
                ): IProductInfoPayload => ({
                    quantity: multiSelection.qty,
                    selections: multiSelection.selections,
                    lpImages,
                    selectionsQty: multiSelection.qty,
                    productType: configurableProduct.type,
                }));
            } else if (quantity > 0) {
                productInfoPayload = [{
                    quantity,
                    selections,
                    lpImages,
                    selectionsQty,
                    productType: configurableProduct.type,
                }];
            }
            return productInfoPayload;
        }

        /**
         * iterate over productInfoPayload array and perform add to cart
         * @param productInfoPayload
         * @param options
         * @private
         */
        private async saveAllEmbroidery(productInfoPayload: IProductInfoPayload[], options) {
            const { embroideryRepository } = this.props;
            let cartId = ''; // use for subsequent calls
            await productInfoPayload.reduce(async (previousPromise, productInfo) => {
                await previousPromise;
                const response = await embroideryRepository.save(
                    { ...productInfo, cartId },
                    options,
                    (this._restoredItem || {}),
                );
                if (!cartId) {
                    cartId = response?.cart_id || cartId;
                }
            }, Promise.resolve());
        }

        async saveEc(options, lpImages) {
            const { embroideryRepository } = this.props;

            this.setState({ loadState: 'myproduct' });
            try {
                const { selections, quantity } = this.getSelectionsAndQuantity();

                if (quantity > 0) {
                    let loaded = 0;
                    const maxLoad = lpImages.length;
                    lpImages.map(lpImage => urlToBase64(lpImage.regular).then(async (regularBase64) => {
                        // eslint-disable-next-line no-param-reassign
                        lpImage.regular = regularBase64;
                        urlToBase64(lpImage.large).then(async (largeBase64) => {
                            // eslint-disable-next-line no-plusplus
                            loaded++;
                            // eslint-disable-next-line no-param-reassign
                            lpImage.large = largeBase64;
                            if (loaded === maxLoad) {
                                const itemIdUpdate = this._restoredItem ? this._restoredItem.item_id : false;
                                const dataObj = {
                                    lpImages,
                                    itemIdUpdate,
                                    quantity,
                                    selections,
                                };
                                await embroideryRepository.saveEc(dataObj, options);
                                window.location.href = '/myproducts/index/index?_=_';
                            }
                        });
                    }));
                }
            } catch (e) {
                this.setState({ loadState: 'loaded' });
                throw e;
            }
        }

        async download(options, lpImages, sku) {
            const { embroideryRepository } = this.props;

            this.setState({ loadState: 'downloading' });

            try {
                const { selections, quantity } = this.getSelectionsAndQuantity();

                if (quantity > 0) {
                    let loaded = 0;
                    const maxLoad = lpImages.length;
                    lpImages.map(lpImage => urlToBase64(lpImage.large).then(async (base64) => {
                        loaded += 1;
                        // eslint-disable-next-line no-param-reassign
                        lpImage.large = base64;
                        const currentUrl = window.location.href;
                        if (loaded === maxLoad) {
                            const result = await embroideryRepository.download(
                                {
                                    quantity, selections, lpImages, sku, currentUrl,
                                }, options,
                            );
                            downloadBase64Pdf(result[0], result[1]);
                            this.setState({ loadState: 'loaded' });
                        }
                    }));
                }
            } catch (e) {
                this.setState({ loadState: 'loaded' });
                throw e;
            }
        }

        render() {
            const {
                options,
                colorExclusions,
                loadState,
                faces,
                fonts,
                embroideryState,
                quantity,
                messages,
                liquidPixel,
                embroideryLogoEnabled,
                defaultLogo,
                textEmbroideryHints,
                textEmbroideryRulesPerLine,
                allowedLogoNumbers,
                isPriceReload,
            } = this.state;

            const {
                configurableProduct,
                simpleProduct,
                closeEmbroiderer,
                afterSave,
                children,
                embroideryRepository,
                removeEmbroidery,
                account,
                quantityState,
                isOrderMultiple,
                multiSelections,
                isQuickView,
                isEmbroideryLockdown,
                isSaveFailed,
                setIsSaveFailed,
                liquidPixelBaseConfig,
                bundleItemPriceData,
            } = this.props;

            const embroiderer = {
                options,
                load: this.load.bind(this),
                save: this.save.bind(this),
                saveEc: this.saveEc.bind(this),
                download: this.download.bind(this),
                loadState,
                embroideryState,
                productColor: this.getProductColor(),
                colorExclusions,
                faces,
                quantity,
                quantityState,
                isOrderMultiple,
                multiSelections,
                fonts,
                isEditing: !!this._restoredItem,
                product: simpleProduct,
                close: closeEmbroiderer,
                afterSave,
                messages,
                parentProductType: configurableProduct.type,
                configurableSku: configurableProduct.sku,
                liquidPixel,
                embroideryLogoEnabled,
                defaultLogo,
                textEmbroideryHints,
                textEmbroideryRulesPerLine,
                allowedLogoNumbers,
                embroideryRepository,
                removeEmbroidery,
                account,
                isPriceReload,
                isQuickView,
                isEmbroideryLockdown,
                isSaveFailed,
                setIsSaveFailed,
                liquidPixelBaseConfig,
                bundleItemPriceData,
            };

            return (
                <Component embroiderer={embroiderer}>
                    {children}
                </Component>
            );
        }
    };
}

export const connectAPI: ((a: any) => any) = Component => connectProductEntity(
    connectAccount(
        injectProps(
            'embroideryRepository',
            'appEventBus',
            'ecommerceConfig',
        )(Connect(Component)),
    ),
);
