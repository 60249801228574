import React from 'react';
import {
    IMultiSelection,
} from '@silkpwa/module/react-component/product-config/configurable-product/configurable-product';
import { getSizes } from '@silkpwa/module/react-component/product-config/util';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { IProductImage } from '@silkpwa/magento/api/util/product';
import { getOptionValue } from 'ui/util/get-option-value';
import { sortImages } from 'ui/util/product-images/sort-images';
import {
    MultiConfigurableQuantity,
} from '../../../product-configurator/product-config/quantity/multi-configurable-quantity';
import { IProductConfigData } from '../../../product-configurator/product-config';
import styles from './style.css';

interface IItemProps {
    item: IMultiSelection;
    quantity: IProductConfigData['quantity'];
}

export const Item: React.FC<IItemProps> = ({ item, quantity }) => {
    if (!item.simpleProduct.id) return null;
    const getSelectedOptions = () => Object.keys(item.selections).map(
        key => getOptionValue(x => x.id === Number(key))(item.selections, item.simpleProduct),
    ).join(' - ');
    const sizeOptions = getSizes(item.simpleProduct);
    const attributeId = sizeOptions.id;
    const optionId = item.selections[attributeId];
    const getImageUrl = () => {
        if (item.simpleProduct.images && item.simpleProduct.images.length) {
            const sortedImage: IProductImage = sortImages(item.simpleProduct.images)[0];
            return sortedImage.thumb || sortedImage.image;
        }
        return '';
    };
    return (
        <>
            <div className={styles.item}>
                <div className={styles.productImage}>
                    <img src={getImageUrl()} alt="" />
                </div>
                <div className={styles.productDetailWrapper}>
                    <div className={styles.productDetail}>
                        <div className={styles.title}>
                            <span>
                                {item.simpleProduct.name}
                            </span>
                            <span>
                                {getSelectedOptions()}
                            </span>
                        </div>
                        <div className={styles.price}>
                            <span>
                                {item.simpleProduct.price}
                            </span>
                        </div>
                        <MultiConfigurableQuantity
                            attributeId={attributeId}
                            optionId={optionId}
                            quantity={quantity}
                            className={styles.qtyItemWrapper}
                        />
                    </div>
                    <AccessibleButton
                        tag="div"
                        action={() => quantity.set(0, optionId, attributeId)}
                        className={styles.removeAction}
                    >
                        <i className="fa-solid fa-trash-can" />
                    </AccessibleButton>
                </div>
            </div>
        </>
    );
};
