import React from 'react';
import { IEmbroideryOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { LiquidPixelConfig } from 'ui/component/liquid-pixel/liquid-pixel-config';
import { ILiquidPixelBaseConfigObj } from 'ui/component/liquid-pixel';
import { LiquidPixelPreview } from './liquid-pixel-preview';
import styles from './style.css';

interface LiquidPixelProps {
    baseUrl: string;
    productId: string;
    productColor: string;
    options: IEmbroideryOption[];
    face: string;
    embroideryPage?: object;
    forwardRef: (e: any) => void;
    children: React.ReactNode;
    liquidPixelBaseConfig: ILiquidPixelBaseConfigObj;
}

export const LiquidPixel = ({
    baseUrl,
    productId,
    productColor,
    face,
    options,
    embroideryPage = null,
    forwardRef,
    children,
    liquidPixelBaseConfig,
}: LiquidPixelProps) => {
    const liquidPixelConfig = new LiquidPixelConfig({
        baseUrl,
        productId,
        productColor,
        face,
        options,
        embroideryPage,
    });
    liquidPixelConfig.setBaseConfig(liquidPixelBaseConfig);
    liquidPixelConfig.constructPreviewUrl();

    return (
        <div className={styles.images} ref={forwardRef}>
            <LiquidPixelPreview
                key={liquidPixelConfig.getPreviewUrl()}
                imageLink={liquidPixelConfig.getPreviewUrl()}
                zoomImageLink={liquidPixelConfig.getZoomPreviewUrl()}
            />
            {children}
        </div>
    );
};
