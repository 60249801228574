import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { classes } from '@silkpwa/module/util/classes';
import screenSwitch from 'ui/styles/screen-switch.css';
import { QuantityButtons } from './quantity-button';
import { PricePreview } from './price-preview';
import { SubmitButton } from './submit-button';
import { Disclaimer } from '../preview/disclaimer';
import styles from './style.css';

interface IAddToCartSummaryProps {
    embroiderer: EmbroidererProps;
    embroideryPage: any; // TODO: add embroideryPage type
}

export const AddToCartSummary: React.FC<IAddToCartSummaryProps> = ({ embroiderer, embroideryPage }) => (
    <div className={styles.configureFooter}>
        <div className={styles.configureFooterFirstRow}>
            <div className={screenSwitch.showOnDesktop}>
                <Disclaimer />
            </div>
        </div>
        <div className={styles.configureFooterRow}>
            <div>
                {!embroiderer.isOrderMultiple && <QuantityButtons embroiderer={embroiderer} />}
            </div>
            <div>
                <PricePreview embroiderer={embroiderer} />
            </div>
            <div className={classes(screenSwitch.showOnMobile, styles.disclaimer)}>
                <Disclaimer />
            </div>
        </div>
        <div className={styles.configureFooterRowSecond}>
            <div>
                <SubmitButton embroiderer={embroiderer} embroideryPage={embroideryPage} />
            </div>
        </div>
    </div>
);
