import React from 'react';
import { LogoDataObject } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import { OptionPage } from 'ui/component/embroidery-configurator/components/option-page';
import { LogoActions } from '../configure/logo/logo-actions';

export const Login = ({ embroiderer, embroideryPage }) => (
    <OptionPage
        embroiderer={embroiderer}
        embroideryPage={embroideryPage}
    >
        {embroideryPage.logos.map((logo: LogoDataObject, i: number) => (
            <LogoActions
                logoNum={i}
                logo={logo}
                embroiderer={embroiderer}
                embroideryPage={embroideryPage}
            />
            ))}
    </OptionPage>
);
